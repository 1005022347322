
export default function Reset() {
    localStorage.clear();
    window.location.href= "/";

    return (
        {/*<div className="reset-page">
            <div className='wrapper'>
                <div className="reset-wrapper">
                    <h1>Reset All Data?</h1>
                    <button className="toggler"  ></button>
                </div>
            </div>

            <div className="overlay">
                <div className="button-wrapper">
                    <h1>Are you sure?</h1>
                    <div className="no" ></div>
                </div>
             </div>
        <div/> */}
    );
}
