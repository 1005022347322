import React, { useEffect } from "react";
import { withRouter} from "react-router-dom";
import intro from "../../images/problem/problemSplash.png";
import Header from "./../Header.jsx";
import '../../css/problem.css';
/// <reference types='pixi-sound' />
/// <reference types='pixi-animate' />
// import 'pixi.js' ;
// import 'pixi-sound';
// import 'pixi-animate';
// import * as animation from "../../lib/FidgitTips.js" ;
/*global PIXI*/
/*eslint no-undef: "error"*/

export default function Intro() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const projectCount = localStorage.projectCount ? localStorage.projectCount : 1;
    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                // GA Push
                gtag('event', 'intro_click',{
                    "event_category": "Define a Problem", 
                    "event_label": "Define a Problem - Intro page" 
                });
                history.push("/problem/menu");}} 
        />
    ));

    if (projectCount == 1) {
        localStorage.currentProject = 1;
    }

    return (
        <div className="intro-page problem-section">
            <Header active="problem" back="" />
            <div className='wrapper'>
                <div className="intro-wrap">
                    <img src={intro} style={{marginTop: '50px', width: 'auto',maxWidth: '800px',maxHeight: '50vh'}}/>
                </div>
                <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <div className="speech-bubble">
                        <p>Think about a problem you'd like to fix or make better!</p>
                    </div>
                </div>
                <SubmitButton />
            </div>
        </div>
    );
}
