import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import intro from "../../images/build/buildSplash.png";
import Header from "./../Header.jsx";
import '../../css/build.css';

export default function Intro() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                gtag('event', 'intro_click',{
                    "event_category": "Build", 
                    "event_label": "Build - Intro page" 
                });
                history.push("/build/build");}}
        />
    ));

    return (
        <div className="intro-page build-section">
            <Header active="build" back="" />
            <div className='wrapper'>
                <div className="intro-wrap">
                    <img src={intro} style={{marginTop: '110px', width: 'auto',maxWidth: '600px',maxHeight: '50vh'}}/>
                </div>
                <div class="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <div className="speech-bubble">
                        <p>Make a rough draft that shows how your idea works!</p>
                    </div>
                </div>
            </div>

             <SubmitButton />
        </div>
    );
}
