import React, { useEffect } from "react";
import Host from "./Host";
import Theme from "./Theme";

export default function Customize() {
    let pathname = window.location.pathname;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 
    })

    return (
       <div className='customize'>
           <Host />
           <Theme />
       </div>
    );    
}
