import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import Slider from '@material-ui/core/Slider';
import fpo from "../../images/diagramming/diagram-fpo.png";
import Header from "./../Header.jsx";
import '../../css/test.css';

export default function Intro() {
    const [q1, setQ1] = useState();
    const [q2, setQ2] = useState();
    const [q3, setQ3] = useState();

    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    let currentProject = localStorage.currentProject;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const NextButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={()=>{
                let val = document.querySelector('.q3 input').value;
                let textVal = document.querySelector('.paper-container .q3 textarea').value;
                if (textVal !== ''){
                    localStorage.setItem("pr_"+ currentProject + '_test_q3_text', textVal);
                } 
                localStorage.setItem("pr_"+ currentProject + '_test_q3', q3);
                history.push("/test/summary");
            }}
        />
    ));

    const qHandler = (e) => {
        if (e.target.parentNode.classList.contains('question_1')){
            let val = document.querySelector('.cardboard .q1 input').value;
            document.querySelector('.next-arrow').classList.add('q1');
            document.querySelector('.cardboard .q1 .trigger-wrap').style.display = 'block';
            if (val > 0){
                document.querySelector('.next-arrow').style.display = 'block';
            }
            setQ1(val);
        } else if (e.target.parentNode.classList.contains('question_2')){
            let val = document.querySelector('.cardboard .q2 input').value;
            document.querySelector('.next-arrow').classList.add('q2');
            document.querySelector('.cardboard .q2 .trigger-wrap').style.display = 'block';
            if (val > 0){
                document.querySelector('.next-arrow').style.display = 'block';
            }
            setQ2(val);
        } else if (e.target.parentNode.classList.contains('question_3')){
            let val = document.querySelector('.cardboard .q3 input').value;
            document.querySelector('.cardboard .q3 .trigger-wrap').style.display = 'block';
            document.querySelector('.next-arrow').classList.add('q3');
            if (val > 0){
                document.querySelector('.next-wrap').style.display = 'block';
            }
            setQ3(val);
        }        
    }

    const nextClick = (e) => {
        if (e.target.classList.contains('q1')){
            let val = document.querySelector('.cardboard .q1 input').value;
            let textVal = document.querySelector('.paper-container .q1 textarea').value;
            localStorage.setItem("pr_"+ currentProject + '_test_q1', q1);

            if (textVal !== ''){
                localStorage.setItem("pr_"+ currentProject + '_test_q1_text', textVal);
            } else {
                localStorage.setItem("pr_"+ currentProject + '_test_q1_text', '');
            }

            document.querySelector('.next-arrow').style.display = 'none';
            document.querySelector('.cardboard .q1').style.display = 'none';
            document.querySelector('.cardboard .q2').style.display = 'block';
            document.querySelector('.test-section .text-box p').innerHTML = 'Does it have all the parts it needs?';
            document.querySelector('.next-arrow').classList.remove('q1');
        } else if (e.target.classList.contains('q2')){
            let val = document.querySelector('.cardboard .q2 input').value;
            let textVal = document.querySelector('.paper-container .q2 textarea').value;
            localStorage.setItem("pr_"+ currentProject + '_test_q2', q2);

            if (textVal !== ''){
                localStorage.setItem("pr_"+ currentProject + '_test_q2_text', textVal);
            } 

            document.querySelector('.next-arrow').style.display = 'none';
            document.querySelector('.cardboard .q2').style.display = 'none';
            document.querySelector('.cardboard .q3').style.display = 'block';
            document.querySelector('.test-section .text-box p').innerHTML = 'Does the idea solve the problem?';
            document.querySelector('.next-arrow').classList.remove('q2');
        } else if (e.target.classList.contains('q3')){
            let val = document.querySelector('.q3 input').value;
            let textVal = document.querySelector('.paper-container .q3 textarea').value;
            if (textVal !== ''){
                localStorage.setItem("pr_"+ currentProject + '_test_q3_text', textVal);
            } 
            localStorage.setItem("pr_"+ currentProject + '_test_q3', q3);

            document.querySelector('.next-arrow').style.display = 'none';
            document.querySelector('.cardboard .q2').style.display = 'none';
            document.querySelector('.cardboard .q3').style.display = 'block';
            document.querySelector('.test-section .text-box p').innerHTML = 'Does the idea solve the problem?';
            document.querySelector('.next-arrow').classList.remove('q2');
        } 
    }

    const showPaper = (e) =>{
        document.querySelector('.paper-container').classList.add('show');
        document.querySelector('.cardboard').classList.add('under');
        document.querySelector('.next-arrow').classList.add('under');
        if (e.target.parentNode.parentNode.classList.contains('q1')){
            document.querySelector('.paper-container .q1').style.display = 'grid';
        } else if (e.target.parentNode.parentNode.classList.contains('q2')){
            document.querySelector('.paper-container .q2').style.display = 'grid';
        } else if (e.target.parentNode.parentNode.classList.contains('q3')){
            document.querySelector('.paper-container .q3').style.display = 'grid';
        } 
    }

    const hidePaper = (e) =>{
        document.querySelector('.paper-container').classList.remove('show');
        document.querySelector('.cardboard').classList.remove('under');
        document.querySelector('.next-arrow').classList.remove('under');
        document.querySelector('.paper-container .q1').style.display = 'none';
        document.querySelector('.paper-container .q2').style.display = 'none';
        document.querySelector('.paper-container .q3').style.display = 'none';
    }

    return (
        <div className="test-section reflect">
            <Header active="test" back="/test/info" />
            <div className="next-arrow" onClick={nextClick}></div>
            <div className="next-wrap"><NextButton /></div>
            <div className="fidgit-question">
                <div className={"fidgit-parent " + host}></div>
                <div className="text-box">
                    <p>Does this do what I expect?</p>
                </div>                

            </div>

            <div className="cardboard">
                <div className="wrapper">
                    <div className="q1">
                        <Slider
                            className="question_1"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />

                        <div className="trigger-wrap">
                            <h2>Why? Why Not?</h2>
                            <div className="write-button" onClick={showPaper}/>
                        </div>  

                    </div>

                    <div className="q2">
                        <Slider
                            className="question_2"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />

                        <div className="trigger-wrap">
                            <h2>Why? Why Not?</h2>
                            <div className="write-button" onClick={showPaper}/>
                        </div>

                    </div>

                    <div className="q3">
                        <Slider
                            className="question_3"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />

                        <div className="trigger-wrap">
                            <h2>Why? Why Not?</h2>
                            <div className="write-button"  onClick={showPaper}/>
                        </div>

                    </div>
                </div>
            </div>

            <div className="paper-container">
                <div className="wrapper">
                    <div className="q1">
                        <textarea name="test_q1_text" className="paper-textarea" placeholder='Tap to type what you learned from testing or what you could change...' maxLength="140" />
                        <div className="proceed q1" onClick={e =>{
                            e.preventDefault();
                            hidePaper();
                            nextClick(e);
                        }}></div>
                    </div>
                    <div className="q2">
                        <textarea name="test_q2_text" className="paper-textarea" placeholder='Tap to type what you learned from testing or what you could change...' maxLength="140"/>
                        <div className="proceed q2" onClick={e =>{
                            e.preventDefault();
                            hidePaper();
                            nextClick(e);
                        }}></div>
                    </div>
                    <div className="q3">
                        <textarea name="test_q3_text" className="paper-textarea" placeholder='Tap to type what you learned from testing or what you could change...' maxLength="140" />
                        <div className="proceed q3" onClick={e =>{
                            e.preventDefault();
                            hidePaper();
                            nextClick(e);
                            document.querySelector('.next-button').click();
                        }}></div>
                    </div>
                </div>
            </div>
        </div>
    );

}
