import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import intro from "../../images/diagramming/brainstormSplash.png";
import '../../css/diagramming.css';

export default function Intro() {
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                // GA Push
                gtag('event', 'intro_click',{
                    "event_category": "Brainstorm", 
                    "event_label": "Brainstorm - Intro page" 
                });
                history.push("/diagramming/info");
            }}
        />
    ));

    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    return (
      <div className="intro-page diagramming-section">
          <Header active="sketch" back="" />
          <div className='wrapper'>
            <div className="intro-wrap">
                <img src={intro}  style={{marginTop: '0px', width: 'auto', maxWidth: '800px', maxHeight: '50vh'}}/>
            </div>
            <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <div className="speech-bubble">
                        <p>Come up with ideas for how to solve your problem!</p>
                    </div>
                </div>
             <SubmitButton />
          </div>
       </div>
    );
}
