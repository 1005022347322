import React, {useState, useEffect} from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Header from "./../Header.jsx";
import photo from "../../images/diagramming/paperSketch.png";
import draw from "../../images/diagramming/appSketch.png";
import '../../css/overlay.css';
import '../../css/diagramming.css';

export default function Menu() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const [overlay, setOverlay] = useState();
    const [file, setFile] = useState();
    const [filename, setFilename] = useState();
    const gtag = window.gtag;

    let currentProject = localStorage.currentProject;
    let [currentDiagram, setCurrentDiagram] = useState(localStorage.getItem("pr_"+ currentProject + '_currentDiagram') ? localStorage.getItem("pr_"+ currentProject + '_currentDiagram') : 1);
    localStorage.setItem("pr_"+ currentProject + '_currentDiagram', currentDiagram);

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })
    
    useEffect(()=>{
        if (localStorage.getItem("pr_" + currentProject + '_diagram_' + currentDiagram)){
            setCurrentDiagram(parseInt(currentDiagram) + 1);
            localStorage.setItem("pr_"+ currentProject + '_currentDiagram', currentDiagram);
        }
    },[currentDiagram])    

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="done-button"
            onClick={(e) => {
                e.preventDefault();

                gtag('event', 'diagram_upload',{
                    "event_category": "Brainstorm", 
                    "event_label": "Brainstorm - Upload Complete" 
                })            

                const form = document.querySelector('form.upload-form');
                const data = new FormData(form);
                const name = file.name;
                console.log(Array.from(data));
                axios.post("/upload.php", data).then (function(response){
                    console.log(response.data);                    
                    setFilename(response.data);
                    localStorage.setItem("pr_"+ currentProject + "_diagram_" + currentDiagram, "/media/" + response.data);
                    history.push("/diagramming/reflect");
                })
            }}
        />
    ));

    const disabled = (e) => {
        e.preventDefault();
    }

    const onFileChange = (e) => {
        var file = e.target.files[0];
        setFile(e.target.files[0]);
        console.log(file);
        document.querySelector('.upload-trigger label').classList.add('active');
        document.querySelector('.diagramming-section .sub-wrap').style.display = 'block';
    }

    return (
        <div className="diagramming-section">
            <Header active="sketch" back="/diagramming/info" />
            <div className="wrapper ">
                <ul className="menu">
                    <li>
                        <div className='upload-trigger'>
                            <label htmlFor="diagram_upload" className="icon"><span></span></label>
                            <span>Take a Photo of your diagram</span>
                            <form className="upload-form">
                            <input id="diagram_upload" className="inputfile" type="file" name="sketch_upload" accept="image/*" capture="camera" onChange={onFileChange}></input>
                            </form>
                        </div>
                    </li>
                    <li>
                        <Link to='/diagramming/sketch' className="disabled" onClick={disabled}>
                            <img src={draw} className="icon" />
                            <span>Diagram it!</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="sub-wrap" style={{display: 'none'}}>
                <SubmitButton />
            </div>
       </div>
    );
}
