import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../css/designProcess.css"; 

export default function Index() {
    const currentProject = localStorage.currentProject ? localStorage.currentProject : '';
    const storedAppState = localStorage.getItem("pr_" + currentProject + "_appState") ? localStorage.getItem("pr_" + currentProject + "_appState") : 'problem';
    
    if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
        localStorage.setItem("pr_" + currentProject + "_appState", storedAppState);
    }
    
    const [appState, setAppstate ] = useState(storedAppState);
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const NextButton = withRouter(({ history }) => (
        <a href="#" 
            className='next-button'  
            onClick= {e => { 
                e.preventDefault(); 
                history.push("/problem/intro"); 
        }} />
    ));

    const HomeButton = withRouter(({ history }) => (
        <a href="#" 
            className='home-button'  
            onClick= {e => { 
                e.preventDefault(); 
                history.push("/menu"); 
        }} />
    ));

    let ShowNext = (props) => {
         if (appState === 'intro'){
            return <NextButton />
         } else{
            return null;
         }
    }
                
        return (
            
            <div className="wrapper">
                <h1 style={{textAlign: 'right'}}>Design Process</h1>
                <HomeButton />
                <div className={"design-process-grid return " + storedAppState} >
                    <div className="problem">
                        <Link to="/problem/intro" className="highlight"  onClick={e=>{
                            gtag('event', 'dp_menu_click',{
                                "event_category": "Design Process Menu", 
                                "event_label": "DP Menu - Problem" 
                            })                            
                        }}/>
                        <div className="locked"></div>
                        <span className="checked"></span>
                    </div>
                    <div className="mid">                        
                        <div className="test-iterate">
                            <Link to='/test/intro' className="highlight" onClick={e=>{
                                gtag('event', 'dp_menu_click',{
                                    "event_category": "Design Process Menu", 
                                    "event_label": "DP Menu - Test" 
                                }) 
                            }}/>
                            <div className="locked"></div>
                            <span className="checked"></span>
                        </div>
                        <div className="brainstorm">
                            <Link to="/diagramming/intro" className="highlight" onClick={e=>{
                                gtag('event', 'dp_menu_click',{
                                    "event_category": "Design Process Menu", 
                                    "event_label": "DP Menu - Brainstorm" 
                                }) 
                            }}/>
                            <div className="locked"></div>
                            <span className="checked"></span>
                        </div>
                    </div>
                    <div className="build">
                        <Link to='/build/intro' className="highlight" onClick={e=>{
                            gtag('event', 'dp_menu_click',{
                                "event_category": "Design Process Menu", 
                                "event_label": "DP Menu - Build" 
                            }) 
                        }}/>
                        <div className="locked"></div>
                        <span className="checked"></span>
                    </div>
                </div>
                <ShowNext/>
            </div>

        );
   
}
