import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import fpo from "../../images/diagramming/diagram-fpo.png";
import Header from "./../Header.jsx";
import '../../css/build.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Menu() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    var settings = {
      dots: false,
      fade: true,
      arrows: false,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 15000,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1
    };

    const host = localStorage.host ? localStorage.host : 'host_1';
    let currentProject = localStorage.currentProject;
    const diagram = localStorage.getItem("pr_"+ currentProject + "_diagramFinal");

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('menu')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                history.push("/build/review");}}/>
    ));

    return (
        <div className="build-section build">
            <div className="dressing_1" />
            <div className="dressing_2" />
            <Header active="build" back="/build/intro" heading="Build Your Prototype"/>
            <div className="wrapper">
                <div className="diagram">
                    <div class="tape"><img src={diagram} /></div>
                </div>
                <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <Slider {...settings} className="speech-bubble">
                        <p>If something isn't working, is there a different way to do it? Talk with someone nearby!</p>
                        <p>Ask a grown-up for help if you get stuck!</p>
                        <p>Have you built the important parts of your idea? Check your diagram!</p>
                        <p>Do you need to add more to show how your idea works?</p>
                    </Slider>
                </div>
            </div>
            <SubmitButton />
        </div>
    );
}
