import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import '../../css/problem.css';

export default function Complete() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    useEffect(() => {
        document.querySelector("body").classList.add(section);
    });

    let host = localStorage.host;
    let currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    let problem = localStorage.getItem("pr_"+ currentProject + '_problem');    

    const SubmitButton = withRouter(({ history }) => (
        <button 
            style={{margin: '30px auto', display: 'block'}}
            className="sketch-button"
            onClick={(e) => {
                e.preventDefault();

                console.log(e.target.innerHTML);
                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + "_appState", 'sketch');
                }
                localStorage.setItem("pr_"+ currentProject + '_problem', document.querySelector('.problem-statement').innerHTML);
                history.push("/diagramming/intro");

            }}>Let's Plan!
        </button>
    ));

    const Statement = withRouter(({ history }) => (
        <div className="problem-statement" onClick={e=>{
            history.push("/problem/submit");
        }}>
            {problem}
        </div>

    ));

    return (
        <div className="problem-complete">
        <Header active="problem" back="/problem/menu" heading="My Problem"/>
       <div className="wrapper">

            <Statement />     
            <div className="note">
                <div className={"host " + host}/>
                <div className="blurb">
                    <p>What can you make to solve this?</p>
                </div>
            </div>
            <div className="submit-wrapper">
                <SubmitButton />
            </div>
       </div>
       </div>
    );
}
