import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from "./index";
import DP from "./DesignProcess";
import DPR from "./DesignProcessReturn";
import Reset from "./reset";
import ProjectMenu from "./Menu";
import Customize from "./customize/Customize";
import ProblemIntro from "./problem/Intro";
import ProblemMenu from "./problem/Menu";
import ProblemHelp from "./problem/Problem";
import ProblemSubmit from "./problem/Submit";
import ProblemChallengeSubmit from "./problem/ChallengeSubmit";
import ProblemExisting from "./problem/Existing";
import ProblemChallenge from "./problem/Challenge";
import ProblemComplete from "./problem/Complete";
import SketchIntro from "./diagramming/Intro";
import SketchInfo from "./diagramming/Info";
import SketchMenu from "./diagramming/Menu";
import DiagramTool from "./diagramming/Sketch";
import SketchReflect from "./diagramming/Reflect";
import SketchSummary from "./diagramming/Summary";
import SketchChoose from "./diagramming/Choose";

import BuildIntro from "./build/Intro";
import BuildBuild from "./build/Build";
import BuildReview from "./build/Review";

import TestIntro from "./test/Intro";
import TestInfo from "./test/Info";
import TestReflect from "./test/Reflect";
import TestSummary from "./test/Summary";

import IterateIntro from "./iterate/Intro";
import "../fonts/style.css";
import "../css/normalize.css";
import "../css/site.css";

function NoMatch() {
    return <h1 style="{{textAlign: 'center'}}">404</h1>
}

const bodyTheme = localStorage.theme ? localStorage.theme : "theme_1";

var width = window.innerWidth;
if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    // document
    //   .querySelector("meta[name=viewport]")
    //   .setAttribute("content", "width=device-width, initial-scale=0.5");
}

// if (width < 700) {
//   document
//     .querySelector("meta[name=viewport]")
//     .setAttribute("content", "width=device-width, initial-scale=0.5");
// }

function AppRouter() {    

    useEffect(() => {
        document.querySelector("body").classList.add(bodyTheme);
    });

    return (            
        <Router>
            <Switch>
                <Route path="/reset" exact component={Reset} />
                <Route path="/" exact component={Index} />
                <Route path="/intro" exact component={DP} />
                <Route path="/menu" exact component={ProjectMenu} />
                <Route path="/design-process-menu" exact component={DPR} />
                <Route path="/customize/" component={Customize} />

                { /* Problem */}
                <Route path="/problem/intro" component={ProblemIntro} />
                <Route path="/problem/menu" component={ProblemMenu} />
                <Route path="/problem/help" component={ProblemHelp} />
                <Route path="/problem/existing" component={ProblemExisting} />
                <Route path="/problem/challenge" component={ProblemChallenge} />
                <Route path="/problem/submit" component={ProblemSubmit} />
                <Route path="/problem/challengesubmit" component={ProblemChallengeSubmit} />
                <Route path="/problem/complete" component={ProblemComplete} />

                { /* Sketch */}
                <Route path="/diagramming/intro" component={SketchIntro} />
                <Route path="/diagramming/info" component={SketchInfo} />
                <Route path="/diagramming/menu" component={SketchMenu} />
                <Route path="/diagramming/sketch" component={DiagramTool} />
                <Route path="/diagramming/reflect" component={SketchReflect} />
                <Route path="/diagramming/summary" component={SketchSummary} />
                <Route path="/diagramming/choose" component={SketchChoose} />
                
                { /* Build */}
                <Route path="/build/intro" component={BuildIntro} />
                <Route path="/build/build" component={BuildBuild} />
                <Route path="/build/review" component={BuildReview} />

                { /* Test */}
                <Route path="/test/intro" component={TestIntro} />
                <Route path="/test/info" component={TestInfo} />
                <Route path="/test/reflect" component={TestReflect} />
                <Route path="/test/summary" component={TestSummary} />

                {/* Iterate */}
                <Route path="/iterate/intro" component={IterateIntro} />

                { /* 404 */}
                <Route component={NoMatch} />
            </Switch>
        </Router>
    );
}

export default AppRouter;
