import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../css/designProcess.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Index() {
    let currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    const storedAppState = localStorage.getItem("pr_"+ currentProject + '_appState');
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 
    })

    var settings = {
        dots: false,
        fade: true,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        beforeChange: current => {
            console.log(current);
            if (current == 1){ 
                document.querySelector('.fidgit-bubble').classList.add('fade');
                document.querySelector(".graphic .wrap").style.display = 'block';
                document.querySelector('.graphic').classList.add('fade');
            }
        }
    };

    const NextButton = withRouter(({ history }) => (
        <a href="#" 
            className='next-button absolute'  
            onClick= {e => { 
                e.preventDefault(); 

                gtag('event', 'start_project',{
                    "event_category": "DP Intro Page", 
                    "event_label": "DP Intro Page - Initial Problem Created" 
                })      

                localStorage.setItem("pr_"+ currentProject + '_appState', 'problem');
                history.push("/problem/intro"); 
        }} />
    ));
                
    return (
        
        <div className="design-process-intro wrapper">            

                <div className='fidgit-bubble'>
                    <div className={"fidgit " + host}/>
                    <Slider {...settings}>
                        <div className="bubble">Make things that solve problems!</div>
                        <div className="bubble">Using a design process can help.</div>
                        <div className="bubble"></div>
                    </Slider>
                </div>
                
                <div className="graphic" >
                    <h1 style={{textAlign: 'center'}}>Design Process</h1>
                    <div className={"design-process-grid intro " + storedAppState} />
                    <div className="wrap" style={{display: 'none'}}> 
                        <NextButton />
                    </div>
                </div>           
            
        </div>

    );
   
}
