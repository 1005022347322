import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";

export default function Summary() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    let currentProject = localStorage.currentProject;
    const gtag = window.gtag;

    const q1 = localStorage.getItem("pr_"+ currentProject + '_test_q1');
    const q1Text = localStorage.getItem("pr_"+ currentProject + '_test_q1_text') ? localStorage.getItem("pr_"+ currentProject + '_test_q1_text') : '';
    const q2 = localStorage.getItem("pr_"+ currentProject + '_test_q2');
    const q2Text = localStorage.getItem("pr_"+ currentProject + '_test_q2_text') ? localStorage.getItem("pr_"+ currentProject + '_test_q2_text') : "";
    const q3 = localStorage.getItem("pr_"+ currentProject + '_test_q3');
    const q3Text = localStorage.getItem("pr_"+ currentProject + '_test_q3_text') ? localStorage.getItem("pr_"+ currentProject + '_test_q3_text') : '';

    console.log(q1Text, q2Text, q3Text);
  
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    useEffect(() => {
        if (q1Text !== "") {
            document.querySelector('.test-section .q1 .notes').innerHTML = "<p>" + q1Text + "</p>";
            document.querySelector('.test-section .q1 .expand').style.display = 'block';
        }

         if (q2Text !== "") {
            document.querySelector('.test-section .q2 .notes').innerHTML = "<p>" + q2Text + "</p>";
            document.querySelector('.test-section .q2 .expand').style.display = 'block';
        }

         if (q3Text !== "") {
            document.querySelector('.test-section .q3 .notes').innerHTML = "<p>" + q3Text + "</p>";
            document.querySelector('.test-section .q3 .expand').style.display = 'block';
        }

        switch(q1) {
            case '0':
                document.querySelector('.test-section.summary .q1 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Doesn\'t do what I expect';
                break;
            case '25':
                document.querySelector('.test-section.summary .q1 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Doesn\'t do what I expect';
                break;
            case '50':
                document.querySelector('.test-section.summary .q1 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Doesn\'t do what I expect';
                break;
            case '75':
                document.querySelector('.test-section.summary .q1 .rating').classList.add('happy');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Does what I expect';
                break;        
            case '100':
                document.querySelector('.test-section.summary .q1 .rating').classList.add('hearts');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Does what I expect';
                break;
            default:
                document.querySelector('.test-section.summary .q1 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q1 .message').innerHTML = 'Does what I expect';
                break;
        }

        switch(q2) {
            case '0':
                document.querySelector('.test-section.summary .q2 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Needs more parts';
                break;
            case '25':
                document.querySelector('.test-section.summary .q2 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Needs more parts';
                break;
            case '50':
                document.querySelector('.test-section.summary .q2 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Needs more parts';
                break;
            case '75':
                document.querySelector('.test-section.summary .q2 .rating').classList.add('happy');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Has the parts it needs';
                break;        
            case '100':
                document.querySelector('.test-section.summary .q2 .rating').classList.add('hearts');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Has the parts it needs';
                break;
            default:
                document.querySelector('.test-section.summary .q2 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q2 .message').innerHTML = 'Has the parts it needs';
                break;
        }

        switch(q3) {
            case '0':
                document.querySelector('.test-section.summary .q3 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Doesn\'t solve the problem';
                break;
            case '25':
                document.querySelector('.test-section.summary .q3 .rating').classList.add('sad');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Doesn\'t solve the problem';
                break;
            case '50':
                document.querySelector('.test-section.summary .q3 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Doesn\'t solve the problem';
                break;
            case '75':
                document.querySelector('.test-section.summary .q3 .rating').classList.add('happy');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Solves the problem';
                break;        
            case '100':
                document.querySelector('.test-section.summary .q3 .rating').classList.add('hearts');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Solves the problem';
                break;
            default:
                document.querySelector('.test-section.summary .q3 .rating').classList.add('neutral');
                document.querySelector('.test-section.summary .q3 .message').innerHTML = 'Solves the problem';
                break;
        }
    },[ q1, q2, q3, q1Text, q2Text, q3Text]);

    const toggleNotes = (e) => {
        e.target.parentNode.classList.toggle('open');
    } 

    const SubmitButton = withRouter(({ history }) => (
        <div 
            className="done"
            onClick={() => {
                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + '_appState','complete');
                }

                gtag('event', 'summary_advance',{
                    "event_category": "Test", 
                    "event_label": "Done for now!" 
                })  

                history.push("/menu");}}>
                <div></div>
                <span>Done for now!</span>
        </div>
    ));

    const TriggerButton = withRouter(({ history }) => (
        <div 
            className="build-button"
            onClick={(e) => {
                e.preventDefault();
                
                gtag('event', 'summary_advance',{
                    "event_category": "Test", 
                    "event_label": "Keep Working!" 
                })  

                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + '_appState','complete');
                }
                history.push("/iterate/intro");
            }}> 
                <div></div>
                <span>Keep working!</span>
        </div>
    ));

    return (
        <div className="test-section summary">
            <Header active="test" back="/test/reflect" heading="My Design"/>
            <div className='wrapper question-accord-wrapper'>
                
                <div className='clearfix question-accord q1'>
                    <div className="rating"></div>
                    <div className="message"></div>
                    <div className="expand" onClick={toggleNotes}></div>
                    <div className="notes"></div>
                </div>    

                <div className='clearfix question-accord q2'>
                    <div className="rating"></div>
                    <div className="message"></div>
                    <div className="expand" onClick={toggleNotes}></div>
                    <div className="notes"></div>
                </div>    

                <div className='clearfix question-accord q3'>
                    <div className="rating"></div>
                    <div className="message"></div>
                    <div className="expand" onClick={toggleNotes}></div>
                    <div className="notes"></div>
                </div> 

                <div className="button-wrapper">
                    <TriggerButton />
                    <SubmitButton />
                </div>   
            </div>  
        </div>
    );
}
