import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import intro from "../../images/test/TestSplash.png";
import clipboard from "../../images/test/fidgitClipboard.png";
import Header from "./../Header.jsx";
import '../../css/test.css';

export default function Intro() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const gtag = window.gtag;

    const host = localStorage.host ? localStorage.host : 'host_1';

    useEffect(() => {
        document.querySelector("body").classList.add(section);
    });

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                gtag('event', 'intro_click',{
                    "event_category": "Test", 
                    "event_label": "Test - Intro page" 
                });
                history.push("/test/info");}}
        />
    ));

    return (
        <div className="intro-page test-section">
            <Header active="test" back="" />
            <div className='wrapper'>
                <div className="intro-wrap">
                    <img src={intro} style={{marginTop: '110px', width: 'auto',maxWidth: '800px',maxHeight: '50vh'}}/>
                </div>
                <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}>
                        <img src={clipboard} />
                    </div>
                    <div className="speech-bubble">
                        <p>Test your idea! How well does it work?</p>
                    </div>
                </div>
            </div>

             <SubmitButton />
        </div>
    );
}
