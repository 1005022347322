import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import Slider from '@material-ui/core/Slider';
import fpo from "../../images/diagramming/diagram-fpo.png";
import talk from "../../images/fidgits/props/letstalk.png";
import Header from "./../Header.jsx";

export default function Intro() {
    const [q1, setQ1] = useState();
    const [q2, setQ2] = useState();
    const [q3, setQ3] = useState();
    let [diagram, setDiagram] = useState();
    let currentProject = localStorage.currentProject;
    const currentDiagram = localStorage.getItem("pr_"+ currentProject + '_currentDiagram');
    const host = localStorage.host ? localStorage.host : 'host_1';

    const NextButton = withRouter(({ history }) => (
        <button 
            className="next-button"
            onClick={()=>{
                let val = document.querySelector('.q3 input').value;
                setQ3(val);
                localStorage.setItem("pr_"+ currentProject + '_q3_' + currentDiagram, val);
                history.push("/diagramming/summary");
            }}
        />
    ));

    useEffect(()=>{  
        setDiagram(localStorage.getItem("pr_"+ currentProject + "_diagram_" + currentDiagram));
    },[diagram])

    const qHandler = (e) => {
        if (e.target.parentNode.classList.contains('question_1')){
            let val = document.querySelector('.q1 input').value;
            document.querySelector('.next-arrow').classList.add('q1');
            if (val > 0){
                document.querySelector('.next-arrow').style.display = 'block';
            }
            setQ1(val);
        } else if (e.target.parentNode.classList.contains('question_2')){
            let val = document.querySelector('.q2 input').value;
            document.querySelector('.next-arrow').classList.add('q2');
            if (val > 0){
                document.querySelector('.next-arrow').style.display = 'block';
            }
            setQ2(val);
        } else if (e.target.parentNode.classList.contains('question_3')){
            let val = document.querySelector('.q3 input').value;
            document.querySelector('.next-arrow').classList.add('q3');
            if (val > 0){
                document.querySelector('.next-button').style.display = 'block';
            }
            setQ3(val);
        }        
    }

    const nextClick = (e) => {
        if (e.target.classList.contains('q1')){
            let val = document.querySelector('.q1 input').value;
            localStorage.setItem("pr_"+ currentProject + "_q1_" + currentDiagram, val);
            document.querySelector('.next-arrow').style.display = 'none';
            document.querySelector('.cardboard .q1').style.display = 'none';
            document.querySelector('.cardboard .q2').style.display = 'block';
            document.querySelector('.next-arrow').classList.remove('q1');
        } else if (e.target.classList.contains('q2')){
            let val = document.querySelector('.q2 input').value;
            localStorage.setItem("pr_"+ currentProject + "_q2_" + currentDiagram, val);
            document.querySelector('.next-arrow').style.display = 'none';
            document.querySelector('.cardboard .q2').style.display = 'none';
            document.querySelector('.cardboard .q3').style.display = 'block';
            document.querySelector('.next-arrow').classList.remove('q2');
        } 
    }

    return (
        <div className="diagramming-section reflect">
            <Header active="diagramming" back="" />
            <div className="diagram-image">
                <img src={diagram} />
            </div>

            <div className="fidgit-wrapper">
                <div className={"fidgit-parent " + host}><span>Let's Talk!</span></div>
                <button className="next-arrow" onClick={nextClick}/>
                <NextButton />
            </div>

            <div className="cardboard">
                <div className="wrapper">
                    <div className="q1">
                        <Slider
                            className="question_1"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />
                        <h2> How well does your diagram show your idea? </h2>
                    </div>

                    <div className="q2">
                        <Slider
                            className="question_2"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />
                        <h2> Is this something you can build? </h2>
                    </div>

                    <div className="q3">
                        <Slider
                            className="question_3"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-custom"
                            step={25}
                            valueLabelDisplay="on"
                            marks
                            onChange={qHandler}
                        />
                        <h2> Do you know what materials to use?</h2>
                    </div>
                </div>
            </div>
        </div>
    );

}
