import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import TouchBackend from "react-dnd-touch-backend";
import HTML5Backend from "react-dnd-html5-backend-cjs";
import Bin from "./Bin";
import Box from "./Box";
import ItemTypes from "./ItemTypes";
import Header from "./../Header.jsx";
import update from "immutability-helper";
import '../../css/problem.css';
import '../../css/problem-options.css';

const Container = () => {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const gtag = window.gtag;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })
    
    let currentProject = localStorage.currentProject;
    const storedAppState = localStorage.getItem("pr_"+ currentProject + '_appState');
    const [appState, setAppstate] = useState(storedAppState);
    const host = localStorage.host;  
    let problem = localStorage.getItem("pr_"+ currentProject + '_problem');

    const family = {
        what: ["walk", "cook", "see movies"],
        where: [
            ["at a beach", "in a park", "on stairs"],
            ["on a stove", "in a microwave", "on a grill"],
            ["at a theater", "on tv", "on a tablet"]
        ]
    };

    const friend = {
        what: ["play games", "make messes", "do art"],
        where: [
            ["at home", "at school", "at a park"],
            ["in their room", "at dinner", "outside"],
            ["at home", "outside", "at school"]
        ]
    };

    const pet = {
        what: ["play with me", "make messes", "sleep"],
        where: [
            ["indoors", "at a park", "in water"],
            ["where they eat", "where they sleep", "where they play"],
            ["in their bed", "near me", "anywhere"]
        ]
    };

    const data = { family, friend, pet };

    // Set State Vars
    const [bins, setBins] = useState([
        { accepts: [ItemTypes.WHO], lastDroppedItem: null },
        { accepts: [ItemTypes.WHAT], lastDroppedItem: null },
        { accepts: [ItemTypes.WHERE], lastDroppedItem: null }
    ]);

    const [whoBoxes] = useState([
        { name: "family", type: ItemTypes.WHO },
        { name: "friend", type: ItemTypes.WHO },
        { name: "pet", type: ItemTypes.WHO },
        { name: "other", type: ItemTypes.WHO }
    ]);

    const [whatBoxes, setWhatBoxes] = useState([
        { name: "", type: ItemTypes.WHAT },
        { name: "", type: ItemTypes.WHAT },
        { name: "", type: ItemTypes.WHAT },
        { name: "other", type: ItemTypes.WHAT }
    ]);

    const [whereBoxes, setWhereBoxes] = useState([
        { name: "", type: ItemTypes.WHERE },
        { name: "", type: ItemTypes.WHERE },
        { name: "", type: ItemTypes.WHERE },
        { name: "other", type: ItemTypes.WHERE }
    ]);

    const storedWho = localStorage.who ? localStorage.who : "";
    const [who, setWho] = useState(storedWho);

    const storedWhat = localStorage.what ? localStorage.what : "";
    const [what, setWhat] = useState(storedWhat);

    const storedWhere = localStorage.where ? localStorage.where : "";
    const [where, setWhere] = useState(storedWhere);

    const storedOther = localStorage.other ? localStorage.other : 'false';
    const [other, setOther] = useState(storedOther);

    let populateWhat = useCallback(
        name => {
            let options = data[name].what;
            setWhatBoxes(
                update(whatBoxes, [
                    { name: { $set: options[0] } },
                    { name: { $set: options[1] } },
                    { name: { $set: options[2] } }
                ])
            );
        },
        [data, whatBoxes]
    );

    let populateWhere = useCallback(
        (name, key) => {
            let options = data[who].where[key];

            setWhereBoxes(
                update(whereBoxes, [
                    { name: { $set: options[0] } },
                    { name: { $set: options[1] } },
                    { name: { $set: options[2] } }
                ])
            );
        },
        [who, data, whereBoxes]
    );

    const handleDrop = (index, item=null) => {
        const name = item.name;
        const key = item.index;
        const type = item.type;

        if (name === 'other'){
            setOther('true');
            localStorage.other = 'true';
        }

        switch (type) {
            case "who":
                setWho(name);
                localStorage.setItem("who", name);
                break;
            case "what":
                setWhat(name);
                localStorage.setItem("what", name);
                localStorage.setItem("whatInt", key);
                break;
            case "where":
                setWhere(name);
                localStorage.setItem("where", name);
                localStorage.setItem("whereInt", key);
                break;
            default:
                break;
        }

        setBins(
            update(bins, {
                [index]: {
                    lastDroppedItem: {
                        $set: item
                    }
                }
            })
        );
    };

    const onOtherSubmit = (type) => {
        const otherVal = document.querySelector('.other_'+ type).value;
        console.log(otherVal);
        if(otherVal !== ''){

            switch (type) {
                case "who":                
                    setWho(otherVal);
                    localStorage.setItem("who", otherVal);
                    document.querySelector('.heading').innerHTML = 'What do they like to do?';            
                    break;
                case "what":
                    setWhat(otherVal);
                    localStorage.setItem("what", otherVal);
                    document.querySelector('.heading').innerHTML = 'Where do they do this?';
                    break;
                case "where":
                    setWhere(otherVal);
                    localStorage.setItem("where", otherVal);
                    break;
                default:
                    break;
            }
        }
    }

    const SubmitButton = withRouter(({ history }) => (
        <a href="/" className="okay done-button" 
            onClick= {e => { 
                e.preventDefault();

                gtag('event', 'text_submit',{
                    "event_category": "Define a Problem", 
                    "event_label": "WWW - Text Entry" 
                })   

                var problem_final = document.querySelector('.problem_final').value;
                if (problem_final !== ''){
                    localStorage.setItem("pr_"+ currentProject + '_problem', problem_final)
                    setWho('');
                    setWhat('');
                    setWhere('');
                    localStorage.setItem("who", '');
                    localStorage.setItem("what", '');
                    localStorage.setItem("where", ''); 
                    history.push("/problem/complete");
                }
                 
            }}/>
    ));

    const triggerSubmit = () => {
        document.querySelector('.paper-wrapper .summary').style.display = 'none';
        document.querySelector('.paper-wrapper .submit').style.display = 'block';
    }


    function reset() {
        if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
            setAppstate('problem');
        }
        document.querySelector(".bin.who").innerHTML = "<p>who</p>";
        document.querySelector(".bin.who").classList.remove('other');
        document.querySelector(".bin.what").classList.remove('other');
        document.querySelector(".bin.where").classList.remove('other');
        
        document.querySelector(".bin.what").innerHTML = "<p>what</p>";
        document.querySelector(".bin.who").classList.remove('active');
        document.querySelector(".bin.what").classList.remove('active');
        document.querySelector(".bin.where").classList.remove('active');

        if (other !== '') {
            document.querySelector(".paper-wrapper").classList.remove("tall");
        }

        if (who !== '') {
            document.querySelector(".bin.who").classList.remove(who.split(" ").join("_"));
        }
        
        if (what !== ''){
            document.querySelector(".bin.what").classList.remove(what.split(" ").join("_"));
        }
        
        document.querySelector(".bin.where").innerHTML = "<p>where</p>";
        
        if (where !== ''){
            document.querySelector(".bin.where").classList.remove(where.split(" ").join("_"));
            document.querySelector('.paper-wrapper').classList.remove('tall');
            document.querySelector('.qHolder').classList.remove('small');
        }       

        document.querySelector(".options.who").style.display = "flex";
        document.querySelector(".options.what").style.display = "none";
        document.querySelector(".options.where").style.display = "none";
        document.querySelector(".submit").style.display = "none";
        document.querySelector(".otherBox").style.display = "none";
        document.querySelector('.options-wrapper').style.display = 'flex';
        document.querySelector('.heading').innerHTML = 'Who do you want to help?';
        document.querySelector('.heading').style.display = "block";
        setWho("");
        setWhat("");
        setWhere("");
        setOther("false");
        localStorage.what = "";
        localStorage.whatInt = "";
        localStorage.who = "";
        localStorage.where = "";
        localStorage.other = "false";
    }

    window.reset = reset;

    const RestartButton = withRouter(() => (
        <a href="/" className="okay restart" 
            onClick={e =>{
                e.preventDefault();
                reset();
            }}/>
    ));

    const BackButton = withRouter(({ history }) => (
        <a href="#" 
            className='back-button' 
            style={{zIndex: '11'}} 
            onClick= {e => { 
                e.preventDefault();
                reset(); 
                history.push('/problem/menu'); 
            }}/>
        ));

    let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    let backend = HTML5Backend;

    if (supportsTouch === true) {
        backend = TouchBackend;
    }

    // Show appropriate Options
    useEffect(() =>{
        if (who === ''){
            document.querySelector(".options.who").style.display = "flex";
            document.querySelector('.bin.who p').classList.add('droppable');
            document.querySelector('.bin.what p').classList.remove('droppable');
            document.querySelector('.bin.where p').classList.remove('droppable');
        } else if (what === ''){
            document.querySelector(".options.what").style.display = "flex"; 
            document.querySelector(".restart").style.display = 'block';        
        } else if (where === ''){
            document.querySelector(".options.where").style.display = "flex";
            document.querySelector(".restart").style.display = 'block';
        } 
    },[ who, what, where, RestartButton])

    //Who Effect
    useEffect(() => {
        if (who !== "") {
            
            document.querySelector('.bin.what p').classList.add('droppable');
            document.querySelector('.bin.where p').classList.remove('droppable');
            if (other !== 'true'){     
                document.querySelector('.heading').innerHTML = 'What do they like to do?';           
                document.querySelector('.options-wrapper').style.display = 'flex';
                document.querySelector('.otherBox').style.display = 'none';
                document.querySelector(".options.who").style.display = "none";
                document.querySelector(".options.what").style.display = "flex";
                populateWhat(who);
                document.querySelector(".bin.who").innerHTML = "<p></p><span>" + who +"</span>";
                document.querySelector(".bin.who").classList.add(who);   
                document.querySelector(".bin.who").classList.add('active');            
            } else {
                // document.querySelector('.paper-wrapper').classList.add('tall');
                document.querySelector('.options-wrapper').style.display = 'none';
                document.querySelector('.otherBox').style.display = 'block';
                document.querySelector(".bin.who").classList.add('other');
                document.querySelector(".bin.who").classList.add('active');
                document.querySelector(".bin.who").innerHTML = "<p></p><span>" + who +"</span>";
                if (who === 'other'){
                    document.querySelector('.otherBox .other.who').style.display = "block"; 
                } else {
                    document.querySelector('.otherBox .other.who').style.display = "none";
                    document.querySelector('.otherBox .other.what').style.display = "block";
                }                                  
            }
        } 

    }, [who, other]);

    //What Effect
    useEffect(() => {
        if (what !== "") {
            
            document.querySelector('.bin.where p').classList.add('droppable');
            if (other !== 'true'){
                let whatClass = what.split(" ").join("_");
                document.querySelector('.heading').innerHTML = 'Where do they do this?';
                document.querySelector('.options-wrapper').style.display = 'flex';
                document.querySelector('.otherBox').style.display = 'none';
                document.querySelector(".options.what").classList.add('slide');
                document.querySelector(".options.what").style.display = "none";
                document.querySelector(".options.what").classList.remove('slide');
                document.querySelector(".options.where").style.display = "flex";
                populateWhere(what, localStorage.whatInt); 
                document.querySelector(".bin.what").classList.add('active');
                document.querySelector(".bin.what").innerHTML = "<p></p><span>" + what + "</span>";
                document.querySelector(".bin.what").classList.add(whatClass);
                
            } else {
                // document.querySelector('.paper-wrapper').classList.add('tall');
                document.querySelector('.options-wrapper').style.display = 'none';
                document.querySelector('.otherBox').style.display = 'block';
                document.querySelector(".bin.what").classList.add('other');
                document.querySelector(".bin.what").classList.add('active');
                document.querySelector(".bin.what").innerHTML = "<p></p><span>" + what + "</span>";
                if (what === 'other'){
                    document.querySelector('.otherBox .other.what').style.display = "block"; 
                } else {
                    document.querySelector('.otherBox .other.what').style.display = "none";
                    document.querySelector('.otherBox .other.where').style.display = "block";
                }                                  
            }
        } 

    }, [what, other]);

     //Where Effect
    useEffect(() => {
        if (where !== "") {
            if (other !== 'true'){
                let whereClass = where.split(" ").join("_");
                document.querySelector('.paper-wrapper').classList.add('tall');
                document.querySelector('.qHolder').classList.add('small');
                document.querySelector('.heading').style.display = 'none';
                document.querySelector('.options-wrapper').style.display = 'flex';
                document.querySelector('.otherBox').style.display = 'none';
                document.querySelector(".options.where").style.display = "none";
                document.querySelector(".bin.where").innerHTML = "<p></p>" + "<span>" + where + "</span>";        
                document.querySelector(".bin.where").classList.add(whereClass);
                document.querySelector(".bin.where").classList.add('active');
                document.querySelector(".summary").style.display = "block";
                document.querySelector('a.restart').style.display = 'none';
            } else {
                // document.querySelector('.paper-wrapper').classList.add('tall');
                document.querySelector('.options-wrapper').style.display = 'none';
                document.querySelector('.otherBox').style.display = 'block';
                document.querySelector(".bin.where").classList.add('other');
                document.querySelector(".bin.where").classList.add('active');
                document.querySelector(".bin.where").innerHTML = "<p></p>" + "<span>"+ where +"</span>";
                if (where === 'other'){
                    document.querySelector('.otherBox .other.where').style.display = "block"; 
                } else {
                    document.querySelector('.otherBox .other.where').style.display = "none";
                    document.querySelector('.summary').style.display = "block";
                }                                  
            }
        } 

    }, [where, other, RestartButton]);

    return (
        <div className="problem-section id">
            {/*<BackButton />*/}
            <Header active="problem" back="/problem/menu" func='reset' />
            <h1 className="heading">Who do you want to help?</h1>
            <RestartButton />
            <DndProvider backend={backend}>
                <div className="qHolder" style={{ clear: "both" }} >
                    {bins.map(({ accepts, lastDroppedItem }, index) => (
                        <Bin accept={accepts} lastDroppedItem={lastDroppedItem} onDrop={item => handleDrop(index, item)} key={index}/>
                    ))}
                </div>
                <div className="paper-wrapper">
                    <div className="options-wrapper">
                        <div className="options who" style={{ display: "none",  clear: "both" }}>
                            {whoBoxes.map(({ name, type }, index) => (
                                <Box name={name} type={type} key={index} index={index} />
                            ))}
                        </div>

                        <div className="options what" style={{ display: "none",  clear: "both" }}>
                            {whatBoxes.map(({ name, type }, index) => (
                                <Box name={name} type={type} key={index} index={index} />
                            ))}
                        </div>

                        <div className="options where" style={{ display: "none",  clear: "both" }}>
                            {whereBoxes.map(({ name, type }, index) => (
                                <Box name={name} type={type} key={index} index={index} />
                            ))}
                        </div>
                    </div>

                    <div className="otherBox">
                        <div className="other who" style={{ display: "none",  clear: "both" }}>
                            <h2>Tap to type who you want to help:</h2>
                            <textarea className="paper-textarea other_who" name="other_who" defaultValue="" placeholder="Me? My teacher? Someone else?" maxLength="15"></textarea>
                            <div className="button-grid">
                                <RestartButton />                
                                <button className="done-button submission" onClick={() =>{onOtherSubmit('who')}}/>
                            </div>
                        </div>
                        <div className="other what" style={{ display: "none",  clear: "both" }}>
                            <h2>Tap to type something they do:</h2>
                            <textarea className="paper-textarea other_what" name="other_what" defaultValue="" placeholder="A sport? An activity? What else?" maxLength="15"></textarea>
                            <div className="button-grid">
                                <RestartButton />
                                <button className="done-button submission" onClick={() =>{onOtherSubmit('what')}}/>
                            </div>
                        </div>
                        <div className="other where" style={{ display: "none", clear: "both" }}>
                            <h2>Tap to type a place:</h2>
                            <textarea className="paper-textarea other_where" name="other_where" defaultValue="" placeholder="On the bus? In a pool? Somewhere else?"  maxLength="15"></textarea>
                            <div className="button-grid">
                                <RestartButton />
                                <button className="done-button submission" onClick={() =>{onOtherSubmit('where')}}/>
                            </div>
                        </div>
                    </div>

                    <div className="summary" style={{ display: "none" }}>
                        <h1>Talk with a grown-up!</h1>
                        <ul>
                            <li>Think more about who you're helping and what they’re doing.</li>
                            <li>Why do they do this activity? What things do they use?</li>
                            <li>Is there anything they wish was different?</li>
                            <li>What part could be better, easier, or more fun?</li>
                        </ul>
                        <button className="have_problem" onClick={triggerSubmit}/>
                        <div className={"fidgit-parent " + host}></div>
                    </div>

                    <div className="submit" style={{ display: "none" }}>
                        <div className="button-grid">
                        <textarea className="paper-textarea problem_final" name="problem_final" defaultValue={problem} placeholder='Tap to type your problem here...' maxLength="140"></textarea>
                            <SubmitButton />
                        </div>
                    </div>
                </div>
            </DndProvider>
        </div>            
    );
};
export default Container;
