import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Header from "./../Header.jsx";
import { withRouter } from "react-router-dom";
import '../../css/problem.css';
import update from "immutability-helper";

export default function Choose() {
    var i;
    var diagramList = [];
    let currentProject = localStorage.currentProject;
    const currentDiagram = localStorage.getItem("pr_"+ currentProject + '_currentDiagram');
    let [diagrams, setDiagrams] = useState([]);
    const [populated, setPopulated] = useState('false');
    const [favorite, setFavorite] = useState();
    const [q1Final, setQ1Final] = useState();
    const [q2Final, setQ2Final] = useState();
    const [q3Final, setQ3Final] = useState();

    let SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + "_appState", 'build');
                }
                localStorage.setItem("pr_"+ currentProject + "_diagramFinal", favorite);
                localStorage.setItem("pr_"+ currentProject + "_q1Final", q1Final);
                localStorage.setItem("pr_"+ currentProject + "_q2Final", q2Final);
                localStorage.setItem("pr_"+ currentProject + "_q3Final", q3Final);
                for (i = 1; i <= parseInt(currentDiagram); i++){
                    localStorage.removeItem("pr_"+ currentProject + "_diagram_" + i);
                    localStorage.removeItem("pr_"+ currentProject + '_currentDiagram');
                    localStorage.removeItem("pr_"+ currentProject + '_q1_'+i);
                    localStorage.removeItem("pr_"+ currentProject + '_q2_'+i);
                    localStorage.removeItem("pr_"+ currentProject + '_q3_'+i);
                }

                history.push("/build/intro");}}/>
    ));

    const favoriteToggle = (e) => {
        var selection;

        if (e.target.classList.contains('diagram') || e.target.classList.contains('favorite')) {
            selection = e.target.parentElement;
        } else {
            selection = e.target.parentElement.parentElement;
        }

        document.querySelectorAll("ul.choose li").forEach(node => {
            node.classList.remove("active");
        });
        selection.classList.add('active');
        var fav = selection.getAttribute('data-diagram-index');
        console.log(selection);
        setFavorite(fav);
        setQ1Final(selection.querySelector('.q1').getAttribute('data-question-emoji'));
        setQ2Final(selection.querySelector('.q2').getAttribute('data-question-emoji'));
        setQ3Final(selection.querySelector('.q3').getAttribute('data-question-emoji'));
        document.querySelector(".next-wrap").classList.remove('hide');    
    }
    
    for (i = 0; i < parseInt(currentDiagram); i++){
        var diagram = localStorage.getItem("pr_"+ currentProject + "_diagram_" + (i+1));
        var q1 = localStorage.getItem("pr_"+ currentProject + "_q1_" + (i+1));
        var q1_class;
        var q2 = localStorage.getItem("pr_"+ currentProject + "_q2_" + (i+1));
        var q2_class;
        var q3 = localStorage.getItem("pr_"+ currentProject + "_q3_" + (i+1));
        var q3_class;

        if (q1 === '0'){
            q1_class = 'sad'
        } else if (q1 === '25'){
            q1_class = 'sad'
        } else if (q1 === '50'){
            q1_class = 'neutral'
        } else if (q1 === '75'){
            q1_class = 'happy'
        } else if (q1 === '100'){
            q1_class = 'hearts'
        }

        if (q2 === '0'){
            q2_class = 'sad'
        } else if (q2 === '25'){
            q2_class = 'sad'
        } else if (q2 === '50'){
            q2_class = 'neutral'
        } else if (q2 === '75'){
            q2_class = 'happy'
        } else if (q2 === '100'){
            q2_class = 'hearts'
        }

        if (q3 === '0'){
            q3_class = 'sad'
        } else if (q3 === '25'){
            q3_class = 'sad'
        } else if (q3 === '50'){
            q3_class = 'neutral'
        } else if (q3 === '75'){
            q3_class = 'happy'
        } else if (q3 === '100'){
            q3_class = 'hearts'
        }

        if (diagram && q1 && q2 && q3) {
            diagramList.push({ diagram: diagram , q1: q1, q1_class: q1_class, q2: q2, q2_class: q2_class, q3: q3, q3_class: q3_class,});
        }
        
    }

    useEffect(()=>{
        if (populated !== 'true'){
            setDiagrams(
                update( diagrams, { $set: diagramList })
            )
            setPopulated('true')
        }
        
    }, [populated])    

    return (
        <div className="diagramming-section choose">
        <Header active="sketch" back="/diagramming/summary" heading="Choose a diagram to build"/>
        <div className="wrapper">
            <ul className="choose">
                {diagrams.map((item, index) => (
                    <li onClick={favoriteToggle} data-diagram-index={item.diagram} >
                        <div className="favorite"></div>                    
                        <div className="diagram">
                            <img src={item.diagram} alt={"diagram " + (index + 1) + ". "} />
                        </div>
                        <div class="questions">
                            <div className={'question q1 ' + item.q1_class} data-question-emoji={item.q1}></div>
                            <div className={'question q2 ' + item.q2_class} data-question-emoji={item.q2}></div>
                            <div className={'question q3 ' + item.q3_class} data-question-emoji={item.q3}></div>
                        </div>
                    </li>
                ))}
           </ul>
           <div className="next-wrap hide">
                <SubmitButton />
           </div>
        </div>
        </div>
    );
}
