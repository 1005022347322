import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import intro from "../images/intro/logo.png";
import subhead from "../images/intro/MakeStuff.png";
import "../css/designProcess.css"; 

export default function Index() {
    const projectCount = localStorage.projectCount ? localStorage.projectCount : 1;
    localStorage.projectCount = projectCount;

    const currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    localStorage.currentProject = currentProject;
    const gtag = window.gtag;

    const storedAppState = localStorage.getItem('pr_' + currentProject + "_appState") ? localStorage.getItem('pr_' + currentProject + "_appState") : "intro";
    
    if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
        localStorage.setItem('pr_' + currentProject + "_appState", storedAppState);
    }

    const [appState, setAppstate ] = useState(storedAppState);

    const NextButton = withRouter(({ history, link }) => (       
        <a href="#" 
            className='next-button absolute'  
            onClick= {e => { 
                e.preventDefault();
                // GA Push
                gtag('event', 'intro_click',{
                    "event_category": "Splash Page", 
                    "event_label": "Splash - Intro Click" 
                }); 
                history.push(link); 
        }} />
    ));

    const Button = () => {
        if (projectCount > 1 || (appState !== 'problem' && appState !== 'intro')) {
            return <NextButton link='/menu'/>
        } else {
            return <NextButton link="/customize" />
        }
    }

    useEffect(() => {
        document.querySelector("body").classList.add('intro');
    });

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {

                history.push("/build/build");}}
        />
    ));

    return (
        <div className="intro-page intro">
            <div className="dressing_1" />
            <div className="dressing_2" />
            <div className='wrapper'>
                <div className="intro-wrap">
                    <img src={intro} style={{marginTop: '110px', width: 'auto',maxWidth: '600px',maxHeight: '50vh'}}/>
                    <img src={subhead} style={{marginTop: '20px', width: 'auto',maxWidth: '600px',maxHeight: '10vh'}}/>
                </div>
            </div>

             <Button />
        </div>
    );
   
}
