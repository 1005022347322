import React from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";

export default function DiagramTool() {
    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                history.push("/diagramming/reflect");}}/>
    ));

    return (
      <div className="intro-page">
        <Header active="sketch" back="/diagramming/menu" />
            <div className='wrapper'>
                {/*<img style={{display:'block',margin: '0 auto', width: 'auto',maxHeight: '75vh'}} src={Diagram}  alt="Sketch" />*/}
                <SubmitButton />
            </div>
       </div>
    );
}
