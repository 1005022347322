import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";

export default function Submit() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const gtag = window.gtag;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } else if (document.querySelector('body').classList.contains('challenge')){
            document.querySelector('body').classList.remove('challenge');
        } 

        document.querySelector("body").classList.add(section);
        document.querySelector(".paper-container").classList.add('show');
    })

    let currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    let problem = localStorage.getItem("pr_"+ currentProject + '_problem') ? localStorage.getItem("pr_"+ currentProject + '_problem') : '';

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="done-button"
            onClick={(e) => {
                e.preventDefault();
                localStorage.setItem("pr_"+ currentProject + '_problem', document.querySelector('textarea').value);
                console.log(localStorage.getItem("pr_"+ currentProject + '_problem'));

                gtag('event', 'text_submit',{
                    "event_category": "Define a Problem", 
                    "event_label": "Known Problem - Text entry" 
                })  
                
                history.push("/problem/complete");

            }}/>
    ));

    return (
        <div className="problem-section submission">
            <Header active="problem" back="/problem/menu" heading="I know what problem to solve!"/>

            <div className="paper-container">
                <div className="wrapper">
                    <textarea className="paper-textarea" placeholder='Tap to type your problem here...' defaultValue={problem} maxLength="140"/>
                    <SubmitButton />
                </div>
           </div>
        </div>
    );
}
