import React, { useEffect } from "react";
import { withRouter} from "react-router-dom";
import intro from "../../images/iterate/iterateSplash.png";
import Header from "./../Header.jsx";
import '../../css/problem.css';

export default function Intro() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const projectCount = localStorage.projectCount ? localStorage.projectCount : 1;
    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                gtag('event', 'intro_click',{
                    "event_category": "Iterate", 
                    "event_label": "Iterate - Intro page" 
                });
                history.push("/design-process-menu");}} 
        />
    ));



    return (
        <div className="intro-page problem-section">
            <Header active="problem" back="" />
            <div className='wrapper'>
                <div className="intro-wrap">
                    <img src={intro} style={{marginTop: '50px', width: 'auto',maxWidth: '800px',maxHeight: '50vh'}}/>
                </div>
                <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <div className="speech-bubble">
                        <p>Change your prototype or think of a whole new solution to the problem!</p>
                    </div>
                </div>
                <SubmitButton />
            </div>
        </div>
    );
}
