import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import fidgit from "../../images/test/purpleFidgits.png";
import TextBubble from "../../images/fidgits/props/askingQuestions.png";
import TextBubble2 from "../../images/fidgits/props/havingDiscussion.png";
import "../../css/test.css";

export default function Submit() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    let currentProject = localStorage.currentProject;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
        document.querySelector(".paper-container").classList.add('show');
    })

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={(e) => {
                e.preventDefault();
                history.push("/test/reflect");

            }}/>
    ));

    return (
        <div className="test-section">
            <Header active="test" back="/test/intro" heading="Test my prototype!"/>

            <div className="paper-container">
                <div className="wrapper">
                    <div className="info-section">
                        <div className={"fidgit-tips " + host} ><img src={TextBubble} alt="text bubble. " class="bubble1"/> <img src={TextBubble2} alt="text bubble." class="bubble2"/></div>
                        <ul>
                            <li><span>Use the prototype you built! (Or imagine how you'd use it!)</span></li>
                            <li><span>Think about how your prototype is working. Does it solve the problem?</span></li>
                            <li><span>Show someone else how your prototype works. Ask what they think works well and what could be better.</span></li>
                        </ul>
                    </div>
                </div>
           </div>
           <SubmitButton />
        </div>
    );
}
