import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../../css/problem.css';
import '../../css/host.css';
import Header from "./../Header.jsx";
import next from "./../../images/nextButton.png";

export default function Menu() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;
   
    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    return (
        <div className="problem-section">
            <Header active="problem" back="/problem/intro"/>
            <div className="wrapper menu">
                <ul>
                    <li>   
                        <Link to='/problem/existing' onClick={e=>{
                                gtag('event', 'problem_menu_click',{
                                    "event_category": "Define a Problem", 
                                    "event_label": "Problem Menu - Challenge" 
                                })                            
                            }}>   
                            <span className="icon"/>
                            <span className="arrow">
                                <img src={next} alt="Next Button. "/>
                            </span>
                            <p>Pick a Design Challenge</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/problem/help' onClick={e=>{
                                gtag('event', 'problem_menu_click',{
                                    "event_category": "Define a Problem", 
                                    "event_label": "Problem Menu - WWW" 
                                })                            
                            }}>
                            <span className="icon"/>
                            <span className="arrow">    
                                <img src={next} alt="Next Button. "/>
                            </span>
                            <p>Help someone</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/problem/submit'>
                            <span className="icon"/>
                            <span className="arrow" onClick={e=>{
                                gtag('event', 'problem_menu_click',{
                                    "event_category": "Define a Problem", 
                                    "event_label": "Problem Menu - Write In" 
                                })                            
                            }}>
                                <img src={next} alt="Next Button. "/>
                            </span>
                            <p>I know what problem to solve</p>
                        </Link>
                    </li>
                </ul>
           </div>
           <div className={"host " + host}/>
       </div>
    );
}
