import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import fpo from "../../images/diagramming/diagram-fpo.png";
import Header from "./../Header.jsx";

export default function Intro() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    let currentProject = localStorage.currentProject;
    const currentDiagram = localStorage.getItem("pr_"+ currentProject + '_currentDiagram') ? localStorage.getItem("pr_"+ currentProject + '_currentDiagram') : 1;
    const gtag = window.gtag;

    const q1 = localStorage.getItem("pr_"+ currentProject + "_q1_" + currentDiagram) ? localStorage.getItem("pr_"+ currentProject + "_q1_" + currentDiagram) : localStorage.getItem("pr_"+ currentProject + "_q1Final");
    const q2 = localStorage.getItem("pr_"+ currentProject + "_q2_" + currentDiagram) ? localStorage.getItem("pr_"+ currentProject + "_q2_" + currentDiagram) : localStorage.getItem("pr_"+ currentProject + "_q2Final");
    const q3 = localStorage.getItem("pr_"+ currentProject + "_q3_" + currentDiagram) ? localStorage.getItem("pr_"+ currentProject + "_q3_" + currentDiagram) : localStorage.getItem("pr_"+ currentProject + "_q3Final");

    let [diagram, setDiagram] = useState();

    useEffect(()=>{
        if (localStorage.getItem("pr_"+ currentProject + "_diagramFinal")) { 
            setDiagram(localStorage.getItem("pr_"+ currentProject + "_diagramFinal"));
        } else if (currentDiagram){  
            setDiagram(localStorage.getItem("pr_"+ currentProject + "_diagram_" + currentDiagram));       
        }
    } ,[diagram])


    useEffect(() => {
        switch(q1) {
            case '0':
                document.querySelector('.diagramming-section.summary .q1').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q1 div').innerHTML = 'Doesn\'t show my idea';
                break;
            case '25':
                document.querySelector('.diagramming-section.summary .q1').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q1 div').innerHTML = 'Doesn\'t show my idea';

                break;
            case '50':
                document.querySelector('.diagramming-section.summary .q1').classList.add('neutral');
                document.querySelector('.diagramming-section.summary .q1 div').innerHTML = 'Doesn\'t show my idea';
                break;
            case '75':
                document.querySelector('.diagramming-section.summary .q1').classList.add('happy');
                document.querySelector('.diagramming-section.summary .q1 div').innerHTML = 'Shows my idea';
                break;        
            case '100':
                document.querySelector('.diagram-rating .list ul li.q1').classList.add('hearts');
                document.querySelector('.diagram-rating .list li.q1 div').innerHTML = 'Shows my idea';
                break;
            default:
                document.querySelector('.diagramming-section.summary li.q1').classList.add('neutral');
                document.querySelector('.diagramming-section.summary li.q1 div').innerHTML = 'Doesn\'t show my idea';
                break;
        }

        switch(q2) {
            case '0':
                document.querySelector('.diagramming-section.summary .q2').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q2 div').innerHTML = 'Too hard to build';
                break;
            case '25':
                document.querySelector('.diagramming-section.summary .q2').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q2 div').innerHTML = 'Too hard to build';

                break;
            case '50':
                document.querySelector('.diagramming-section.summary .q2').classList.add('neutral');
                document.querySelector('.diagramming-section.summary .q2 div').innerHTML = 'Too hard to build';
                break;
            case '75':
                document.querySelector('.diagramming-section.summary .q2').classList.add('happy');
                document.querySelector('.diagramming-section.summary .q2 div').innerHTML = 'I can build it';
                break;        
            case '100':
                document.querySelector('.diagram-rating .list ul li.q2').classList.add('hearts');
                document.querySelector('.diagram-rating .list li.q2 div').innerHTML = 'I can build it';
                break;
            default:
                document.querySelector('.diagramming-section.summary li.q2').classList.add('neutral');
                document.querySelector('.diagramming-section.summary li.q2 div').innerHTML = 'Too hard to build';
                break;
        }

        switch(q3) {
            case '0':
                document.querySelector('.diagramming-section.summary .q3').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q3 div').innerHTML = 'I don\'t have materials';
                break;
            case '25':
                document.querySelector('.diagramming-section.summary .q3').classList.add('sad');
                document.querySelector('.diagramming-section.summary .q3 div').innerHTML = 'I don\'t have materials';

                break;
            case '50':
                document.querySelector('.diagramming-section.summary .q3').classList.add('neutral');
                document.querySelector('.diagramming-section.summary .q3 div').innerHTML = 'I don\'t have materials';
                break;
            case '75':
                document.querySelector('.diagramming-section.summary .q3').classList.add('happy');
                document.querySelector('.diagramming-section.summary .q3 div').innerHTML = 'I have materials';
                break;        
            case '100':
                document.querySelector('.diagram-rating .list ul li.q3').classList.add('hearts');
                document.querySelector('.diagram-rating .list li.q3 div').innerHTML = 'I have materials';
                break;
            default:
                document.querySelector('.diagramming-section.summary li.q3').classList.add('neutral');
                document.querySelector('.diagramming-section.summary li.q3 div').innerHTML = 'I dont\'t have materials';
                break;
        }
    });

    const SubmitButton = withRouter(({ history }) => (
        <div 
            className="build-button"
            onClick={() => {
                
                gtag('event', 'summary_advance',{
                    "event_category": "Brainstorm", 
                    "event_action": "summary_advance", 
                    "event_label": "Build it!" 
                })  

                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + '_appState', 'build');
                }
                
                if (localStorage.getItem("pr_"+ currentProject + '_diagramFinal')){
                    history.push("/build/intro");
                } else if(parseInt(currentDiagram) === 1){
                    localStorage.setItem("pr_"+ currentProject + '_diagramFinal', localStorage.getItem("pr_"+ currentProject + "_diagram_" + currentDiagram));
                    localStorage.setItem("pr_"+ currentProject + "_q1Final", localStorage.getItem("pr_"+ currentProject + '_q1_1'));
                    localStorage.setItem("pr_"+ currentProject + "_q2Final", localStorage.getItem("pr_"+ currentProject + '_q2_1'));
                    localStorage.setItem("pr_"+ currentProject + "_q3Final", localStorage.getItem("pr_"+ currentProject + '_q3_1'));

                    localStorage.removeItem("pr_"+ currentProject + "_diagram_1");
                    localStorage.removeItem("pr_"+ currentProject + '_currentDiagram');
                    localStorage.removeItem("pr_"+ currentProject + '_q1_1');
                    localStorage.removeItem("pr_"+ currentProject + '_q2_1');
                    localStorage.removeItem("pr_"+ currentProject + '_q3_1');
                    history.push("/build/intro");
                } else{
                    history.push("/diagramming/choose");
                }
                }}>
                <div></div>
                <span>Build it!</span>
        </div>
    ));

    const TriggerButton = withRouter(({ history }) => (
        <div 
            className="sketch-button"
            onClick={(e) => {
                e.preventDefault();

                gtag('event', 'summary_advance',{
                    "event_category": "Brainstorm", 
                    "event_label": "Keep Planning" 
                })  

                history.push("/diagramming/menu");
            }}> 
                <div></div>
                <span>Keep planning</span>
        </div>
    ));

    return (
        <div className="diagramming-section summary">
            <Header active="sketch" back="" heading="My Diagram"/>
            <div className='wrapper'>
                <div className="diagram-rating">
                    <div className="diagram">
                        <img src={diagram} />
                    </div>
                    <div className="list">
                        <ul className="clearfix">
                            <li className="q1 clearfix"><span></span><div></div></li>
                            <li className="q2 clearfix"><span></span><div></div></li>
                            <li className="q3 clearfix"><span></span><div></div></li>
                        </ul>
                    </div>
                </div>

                <div className="button-wrapper">
                    <TriggerButton />
                    <SubmitButton />
                </div>
            </div>
            
        </div>
    );
}
