import React from "react";
import { withRouter, Link } from "react-router-dom";
import problem from "../images/design-process/problemButton.png";
import sketch from "../images/design-process/brainstormButton.png";
import build from "../images/design-process/buildButton.png";
import test from "../images/design-process/testButton.png";
import "../css/designProcess.css"; 

const Header = (props) => {
    let back = props.back;
    let heading = props.heading;
    let func = props.func;
    let currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    let progress = props.progress ? props.progress : 'false';
    const gtag = window.gtag;
    
    const storedAppState = localStorage.getItem("pr_"+ currentProject + '_appState');
    console.log(localStorage);

    function Back(){
        if (back !== ''){
            return <BackButton back={back} />                
        } else {
            return null;
        }
    }

    const ShowDPOverlay = () => {
        document.querySelector('.process-overlay').classList.toggle('show');
    }

    const BackButton = withRouter(({ history, back }) => (
        <a href="#" 
            className='back-button'  
            onClick= {e => { 
                e.preventDefault();
                var reset = window.reset; 
                if (func == 'reset'){
                    reset();
                }
                history.push(props.back); 
            }}/>
        ));

    const MenuButton = withRouter(({ history, back }) => (
        <a href="#" 
            className='home-button'  
            onClick= {e => { 
                e.preventDefault(); 
                history.push('/menu'); 
            }}/>
        ));

    function Progress(active){
        return (
            <div>
                {progress && (
                    <ul className="progress" onClick={ShowDPOverlay}>
                        <li className={ props.active === 'problem' ? "active" : ""}><img src={problem} alt="problem" /></li>
                        <li className={ props.active === 'sketch' ? "active" : ""}><img src={sketch} alt="sketch" /></li>
                        <li className={ props.active === 'build' ? "active" : ""}><img src={build} alt="build"/></li>
                        <li className={ props.active === 'test' ? "active" : ""}><img src={test} alt="test"/></li>
                    </ul>
                )}

                {heading && (
                    <h1 className="heading" style={{textAlign: 'center', fontWeight: 'normal'}}>{heading}</h1>
                )}
                
                {progress && (
                <div className="process-overlay">
                    <div className="close" onClick={ShowDPOverlay}></div>
                    <MenuButton />
                    <div className={"design-process-grid " + storedAppState} >
                        <div className="problem">
                            <Link to="/problem/intro" className="highlight"  onClick={e=>{
                                gtag('event', 'dp_menu_click',{
                                    "event_category": "Design Process Menu", 
                                    "event_label": "DP Overlay - Problem" 
                                })                            
                            }}/>
                            <div className="locked"></div>
                            <span className="checked"></span>
                        </div>
                        <div className="mid">                        
                            <div className="test-iterate">
                                <Link to='/test/intro' className="highlight" onClick={e=>{
                                    gtag('event', 'dp_menu_click',{
                                        "event_category": "Design Process Menu", 
                                        "event_label": "DP Overlay - Test" 
                                    }) 
                                }}/>
                                <div className="locked"></div>
                                <span className="checked"></span>
                            </div>
                            <div className="brainstorm">
                                <Link to="/diagramming/intro" className="highlight" onClick={e=>{
                                    gtag('event', 'dp_menu_click',{
                                        "event_category": "Design Process Menu",  
                                        "event_label": "DP Overlay - Brainstorm" 
                                    }) 
                                }}/>
                                <div className="locked"></div>
                                <span className="checked"></span>
                            </div>
                        </div>
                        <div className="build">
                            <Link to='/build/intro' className="highlight" onClick={e=>{
                                gtag('event', 'dp_menu_click',{
                                    "event_category": "Design Process Menu", 
                                    "event_label": "DP Overlay - Build" 
                                }) 
                            }}/>
                            <div className="locked"></div>
                            <span className="checked"></span>
                        </div>
                    </div>
                </div>
                )}
            </div>
        );
    }
    
    return (
        <header className='header'>
            <Back />
            <Progress/>
        </header>
    );
}

export default Header;
