import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import '../../css/problem.css';
import animals from "../../images/problem/activities/AnimalsPreview.png";
import toys from "../../images/problem/activities/ToysandGamesPreview.png";
import home from "../../images/problem/activities/HomePreview.png";
import school from "../../images/problem/activities/SchoolPreview.png";
import clothing from "../../images/problem/activities/ClothingPreview.png";
import '../../css/build.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Menu() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    const gtag = window.gtag;

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    const host = localStorage.host;
    let currentProject = localStorage.currentProject;
    const storedAppState = localStorage.getItem("pr_"+ currentProject + '_appState');

    var settings = {
        dots: false,
        arrows: true,
        centerMode: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 2,
        autoplay: false,
        slidesToScroll: 3,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        variableWidth: false
                    }
                },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false
                }
            }
        ]
    };

    function NextArrow(props) {
      const { onClick } = props;
      return (<div className="next-arrow" onClick={onClick} />);
    }

    function PrevArrow(props) {
      const {  onClick } = props;
      return (<div className="prev-arrow" onClick={onClick}/>);
    }

    const SelectButton = withRouter(({ history }) => (
        <div className="select" 
            onClick={(e)=>{
                e.preventDefault();
                var selected = document.querySelector('.slick-current .option-button');
                selected = selected.getAttribute("data-challenge-id");
                localStorage.setItem("pr_"+ currentProject + '_challenge', selected);
                
                gtag('event', 'challenge_click',{
                    "event_category": "Define a Problem", 
                    "event_label": "Challenge - " + selected  
                })  
                history.push("/problem/challenge");
            }}
        />
    ));

    const OptionButton = withRouter(({ history, statement, image, id }) => (
        <div className="option-button" data-challenge-id={id}>
            <div className="photo" style={{backgroundImage: image }}><img className="photo" src={image} alt={statement} /></div>
            <p>{statement}</p>
        </div>
    ));

    return (
      <div className="problem-section problem-existing">
      <Header active="problem" back="/problem/menu" heading="Pick a Design Challenge"/>
         <div className="wrapper problem-slider">
            <Slider {...settings} >
                <OptionButton id="animals" statement="Design something to help you care for animals!" image={animals} />
                <OptionButton id="toys" statement="Design a toy or game that teaches something new!" image={toys} />
                <OptionButton id="home" statement="Don’t like chores? Invent something to make them more fun!" image={home} />
                <OptionButton id="school" statement="Make your school day better with a new invention!" image={school} />
                <OptionButton id="clothing" statement="Create clothing with a new purpose!" image={clothing} />
            </Slider>
            <SelectButton />
         </div>
       </div>
    );
}
