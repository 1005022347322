import React from "react";
import { useDrop } from "react-dnd";

const Bin = ({ accept, lastDroppedItem, onDrop }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isActive = isOver && canDrop;

    return (
        <div ref={drop} className={"bin " + accept.join(", ") + (lastDroppedItem ? " active " + lastDroppedItem.name.toLowerCase().split(" ").join("_") : '')}>
            <p className="droppable">
            {isActive
                ? "Release to drop"
                : `${accept.join(", ")}`}
            
            {lastDroppedItem && (
                lastDroppedItem.name
            )}
            </p>

        </div>
    );
};
export default Bin;
