import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "../../css/host.css";

export default function Host() {
    const storedHost = localStorage.host ? localStorage.host : "";
    const [host, setHost] = useState();
    const firstUpdate = useRef(true);

    const handleHostClick = event => {
        event.preventDefault();        
        document.querySelectorAll("ul li.host").forEach(node => {
            node.classList.remove("active");
        });
        document.querySelector("ul li.host." + event.target.dataset.host).classList.add("active");
        setHost(event.target.dataset.host);
        localStorage.setItem("host", event.target.dataset.host);
        
    };

    const SubmitButton = withRouter(({ history }) => (
        <button className="next-button"
            onClick={(e) => {
                e.preventDefault();
                document.querySelector(".host-wrapper").classList.add(host);
                document.querySelector('h1').style.display = 'none';
                document.querySelector('.customize').classList.add('show');
                document.querySelector(".host-container .next-button").classList.remove('show');
                document.querySelector(".submit-wrapper .next-button").classList.remove('hide');
                
            }}/>
    ));

    useEffect(()=>{
        console.log(firstUpdate.current);
        if (firstUpdate.current){
            firstUpdate.current = false;
        } else{
            document.querySelector(".host-container .next-button").classList.add('show');
        }        
    }, [host])

    return (
        <div className="host-container">
            <h1 style={{ textAlign: 'center', fontSize: '40px' }}>Choose a Fidgit</h1>
            <ul className="host-wrapper">
                <li
                    className="host host_1"
                    data-host="host_1"
                    onClick={handleHostClick}
                />
                <li
                    className="host host_2"
                    data-host="host_2"
                    onClick={handleHostClick}
                />
                <li
                    className="host host_3"
                    data-host="host_3"
                    onClick={handleHostClick}
                />
            </ul>
            <SubmitButton />
        </div>
    );
}
