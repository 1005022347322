import React from "react";
import { useDrag } from "react-dnd";

const Box = ({ name, type, index, isDropped }) => {
    const [{ opacity, backgroundColor }, drag] = useDrag({
        item: { name, type, index },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            backgroundColor: monitor.isDragging() ? 'transparent' : 'inherit',
        })
    });

    return (
        <div className={"option " + name.split(" ").join("_")} ref={drag} style={{ opacity, backgroundColor }}>
            <div className="icon"><div className='image'/></div>
            {isDropped ? '' : <span>{name}</span>}
        </div>
    );
};

export default Box;
