import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import SpotArt from "../images/spot-art.jpg";
import "../css/project-menu.css"; 

function Index () {
    const currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    const storedAppState = localStorage.getItem("pr_" + currentProject + "_appState");
    const projectCount = parseInt(localStorage.projectCount) ? parseInt(localStorage.projectCount) : 1;
    const host = localStorage.host ? localStorage.host : 'host_1';
    const gtag = window.gtag;

    let projectArray = [];

    for (var i = 1; i < (parseInt(projectCount)+ 1); i++){  
        if (localStorage.getItem("pr_" + i +'_problem') !== 'undefined' && localStorage.getItem("pr_" + i +'_problem') !== 'null'){
            var image;

            if (localStorage.getItem("pr_" + i +'_buildFinal') !== undefined && localStorage.getItem("pr_" + i +'_buildFinal') !== null ){
                image = localStorage.getItem("pr_" + i +'_buildFinal');
            } else if (localStorage.getItem("pr_" + i +'_buildPhoto') !== undefined && localStorage.getItem("pr_" + i +'_buildPhoto') !== null ){
                image = localStorage.getItem("pr_" + i + "_buildPhoto");
            } else {
               image = localStorage.getItem("pr_" + i + "_diagramFinal") ? localStorage.getItem("pr_" + i + "_diagramFinal") : SpotArt; 
            }

            projectArray.push({ problem: localStorage.getItem("pr_" + i +'_problem'), key: i, image: image});
        }        
    }

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 
    })

    const toggleTrash = () =>{
        document.querySelector('.project-menu').classList.toggle('trash');
    }

    const toggleOverlay = (target) =>{
        document.querySelector('.overlay').classList.toggle('visible');
        document.querySelector('.overlay .yes').dataset.targetId = target;
    }

    function deleteProblem(id) {
        // GA Push
        gtag('event', 'delete_project',{
            "event_category": "Project Menu", 
            "event_label": "Project menu - Delete Project" 
        });

        localStorage.setItem("pr_" + id + "_problem", 'undefined');
        document.querySelector('.project-wrapper[data-id="'+ id +'"]').style.display = 'none';
        toggleTrash();
        toggleOverlay();
    }

    const AddButton =  withRouter(({ history, index }) => {
        return (
            <button className="add" 
                onClick={ e=>{                    

                    gtag('event', 'add_project',{
                        "event_category": "Project Menu", 
                        "event_label": "Project menu - Add New Project" 
                    })
                    
                    localStorage.projectCount = (parseInt(localStorage.projectCount) + 1);
                    localStorage.currentProject = localStorage.projectCount;                    
                    history.push("/problem/intro");
                }
            }></button>
        )
    });

    const ShowAddButton = () =>{
        console.log(projectArray.length);
        if (projectArray.length < 4){
            return <AddButton />
        } else {
            return null;
        }
    }

    const ShowTrashButton = () =>{
        console.log(projectArray.length);
        if (projectArray.length > 1){
            return <button className="delete" onClick={toggleTrash}></button>
        } else {
            return null;
        }
    }

    const Project = withRouter(({ history, problem, image='', index }) => {
        return (
            <div className="project-wrapper clearfix" data-id={index} 
                onClick={(e)=>{
                    let current = e.target;
                    if (e.target.classList.contains('project-wrapper')){
                        current = e.target.dataset.id;
                    } else if (e.target.classList.contains('statement') || 
                        e.target.classList.contains('trash') || 
                        e.target.classList.contains('photo')){
                        current = e.target.parentNode.dataset.id;
                    } 

                    if (document.querySelector('.project-menu').classList.contains('trash')){
                        console.log('trasher');
                        toggleOverlay(current);
                    } else {
                        localStorage.currentProject = current;
                        history.push("/design-process-menu");
                    }
                    
                }}
            >
                <div className="trash">&times;</div>

                {image && (     
                    <div className="photo" style={{backgroundImage: "url("+image+")"}}/>
                )}
                
                <div className="statement">
                    {problem}
                </div>
            </div>
        )});
               
    return (
        <div className="project-menu">
            <div className="wrapper">
                <div className="menuHead">
                    <h1 style={{textAlign: 'left', fontSize: '50px',fontWeight: 'normal'}}>My Projects</h1>
                    <div className={"button-wrapper " + host}>
                        <Link to="/customize" className="customize"></Link>                        
                        <ShowAddButton />
                        <span>Tap project to Delete</span>
                        <ShowTrashButton />
                    </div>
                </div>
                <div className="projects">
                    {projectArray.map(({ problem, image }, index) => (
                        <Project problem={problem} image={image} key={index+1} index={index+1} />
                    ))}
                </div>  
            </div>

            <div className="overlay">
                <div className="button-wrapper">
                    <h1>Delete this project?</h1>
                    <div className="no" onClick={toggleOverlay}/>
                    <div className="yes" data-target-id="" onClick={e =>{
                        deleteProblem(e.target.dataset.targetId);
                    }}/>
                </div>
            </div>
        </div>
    );
   
}

export default Index;
