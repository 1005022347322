import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import fpo from "../../images/diagramming/diagram-fpo.png";
import Header from "./../Header.jsx";
import '../../css/build.css';

export default function Menu() {
    const [file, setFile] = useState();
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    const host = localStorage.host ? localStorage.host : 'host_1';
    let currentProject = localStorage.currentProject;
    const diagram = localStorage.getItem("pr_"+ currentProject + "_diagramFinal");

    useEffect(() => {
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('menu')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 
        
        document.querySelector("body").classList.add(section);
    });

    const SubmitButton = withRouter(({ history }) => (
        <div 
            className="test-button"
            onClick={(e) => {
                e.preventDefault();
                const form = document.querySelector('form.upload-form');
                const data = new FormData(form);
                const name = file.name;
                if (localStorage.getItem("pr_"+ currentProject + "_appState") !== 'complete'){
                    localStorage.setItem("pr_"+ currentProject + "_appState", 'test');
                }
                axios.post("/upload.php", data).then (function(response){
                    localStorage.setItem("pr_"+ currentProject + "_buildPhoto", "/media/" + response.data);
                    history.push("/test/intro");
                })
        }}/>
    ));

    const onFileChange = (e) => {
        var file = e.target.files[0];
        setFile(e.target.files[0]);
        console.log(file);
        // document.querySelector('.uploader span.message').innerHTML = file.name;
        document.querySelector('.uploader label').classList.add('active');
        document.querySelector('.sub-wrapper').style.display = 'block';
    }

    return (
        <div className="build-section review">
            <div className="dressing_1" />
            <div className="dressing_2" />
            <Header active="build" back="/build/build" heading="Awesome Building!"/>
            <div className="wrapper">
                <div className="uploader">
                    <form className="upload-form">
                        <input id="build_upload" className="inputfile" type="file" name="build_upload" accept="image/*" capture="camera" onChange={onFileChange}></input>
                    </form>
                    <label htmlFor="build_upload" className="icon"><span></span></label>
                    <span className='message'>Take a Photo of your prototype</span>
                    
                </div>
                <div className="fidgit-wrapper">
                    <div className={"fidgit " + host}></div>
                    <div className="sub-wrapper" style={{display: "none"}}>
                        <SubmitButton />
                    </div>
                </div>
            </div>
            
        </div>
    );
}
