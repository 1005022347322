import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import fidgit from "../../images/test/purpleFidgits.png";
import "../../css/problem.css";

export default function Submit() {
    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];
    
    let currentProject = localStorage.currentProject;
    const challenge = localStorage.getItem("pr_"+ currentProject + '_challenge') ? localStorage.getItem("pr_"+ currentProject + '_challenge') : 'animals';
    const host = localStorage.host ? localStorage.host : 'host_1';

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add('challenge');
        document.querySelector("body").classList.add(challenge);
        document.querySelector(".paper-container").classList.add('show');
    })

    

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="lets-go"
            onClick={(e) => {
                e.preventDefault();
                if (document.querySelector('body').classList.contains(challenge)){
                    document.querySelector('body').classList.remove(challenge);
                } 
                history.push("/problem/challengesubmit");

            }}/>
    ));

    return (
        <div className={"problem-challenge " + challenge}>
            <Header active="problem" back="/problem/existing" heading=""/>
            <div className={"header-bg " + challenge}>
                <div className="pants"/>
                <div className="shirt"/>
                <div className="house"/>
                <div className="clouds"/>
            </div>
            <div className="paper-container">
                <div className="wrapper">
                    <ul className="animals">
                        <li><span>Do you have a pet? Or a favorite animal?</span></li>
                        <li><span>What do you have to do to take care of it?</span></li>
                        <li><span>Can you invent something to help animals or make it easier to care for them?</span>
                        </li>
                        <li><span>I know what problem to solve!</span></li>
                    </ul>

                    <ul className="toys">
                        <li><span>Think about your favorite toys or games­. What makes them fun?</span></li>
                        <li><span>Have you ever learned something by playing?</span></li>
                        <li><span>How could a toy or game help someone learn?</span>
                        </li>
                        <li><span>I know what problem to solve!</span></li>
                    </ul>

                    <ul className="home">
                        <li><span>What chores do you do at home?</span></li>
                        <li><span>Which ones do you like the most? The least? Why?</span></li>
                        <li><span>Can you invent something to make your chores easier or more fun?</span>
                        </li>
                        <li><span>I know what problem to solve!</span></li>
                    </ul>

                    <ul className="clothing">
                        <li><span>Think about the clothes you wear for different kinds of activities or weather.</span></li>
                        <li><span>How can your clothes help you do things?</span></li>
                        <li><span>What problem could you solve by inventing new clothing?</span>
                        </li>
                        <li><span>I know what problem to solve!</span></li>
                    </ul>

                    <ul className="school">
                        <li><span>Think about your whole school day.</span></li>
                        <li><span>If you could change one thing about your school day, what would it be?</span></li>
                        <li><span>What could you invent that would improve your school?</span>
                        </li>
                        <li><span>I know what problem to solve!</span></li>
                    </ul>

                    <SubmitButton />
                    
                </div>
           </div>
           <div className={"fidgit-parent " + host}/>
        </div>
    );
}
