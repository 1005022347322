import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import Header from "./../Header.jsx";
import intro from "../../images/diagramming/brainstormSplash.png";
import '../../css/diagramming.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../images/diagramming/brainstorm-slide-1.png";
import slide2 from "../../images/diagramming/brainstorm-slide-2.png";
import slide3 from "../../images/diagramming/brainstorm-slide-3.png";
import Slider from "react-slick";

export default function Info() {
    const host = localStorage.host ? localStorage.host : 'host_1';
    let currentProject = localStorage.currentProject;
    const currentDiagram = localStorage.getItem("pr_"+ currentProject + '_currentDiagram') ? localStorage.getItem("pr_"+ currentProject + '_currentDiagram') : 1;

    const SubmitButton = withRouter(({ history }) => (
        <button 
            className="next-button absolute"
            onClick={() => {
                var appState = localStorage.getItem("pr_"+ currentProject + '_appState');
                if (appState == 'build' || appState == 'test' || appState == 'complete' || currentDiagram > 1){
                    history.push("/diagramming/summary");
                } else {
                    history.push("/diagramming/menu");
                }                
            }}
        />
    ));

    var settings = {
      dots: false,
      fade: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1
    };

    let pathname = window.location.pathname;
    let section = pathname.split('/');
    section = section[1];

    useEffect(()=>{
        if (document.querySelector('body').classList.contains('intro')){
            document.querySelector('body').classList.remove('intro');
        } else if (document.querySelector('body').classList.contains('problem')){
            document.querySelector('body').classList.remove('problem');
        } else if (document.querySelector('body').classList.contains('diagramming')){
            document.querySelector('body').classList.remove('diagramming');
        } else if (document.querySelector('body').classList.contains('build')){
            document.querySelector('body').classList.remove('build');
        } else if (document.querySelector('body').classList.contains('test')){
            document.querySelector('body').classList.remove('test');
        } 

        document.querySelector("body").classList.add(section);
    })

    return (
        <div className="diagramming-section info">
            <Header active="sketch" back="" />
              
            <div className="fidgit-anim">
                <div className={"fidgit " + host}></div>
                <Slider {...settings} className="slide-container">
                    <img src={slide1} alt="Brainstorming. "/>
                    <img src={slide2} alt="Brainstorming. "/>
                    <img src={slide3} alt="Brainstorming. "/>
                </Slider>
            </div>  

            <div className="info-bottom">
                <div className="wrapper">
                    <h3>Brainstorm &amp; Plan</h3>
                    <ul>
                        <li>Think of 2 or 3 ideas that can solve the problem!</li>
                        <li>Pick one idea. How will it work?</li>
                        <li>Draw a diagram like this to help you plan.  </li>
                        <li>A diagram shows the important parts of your idea.</li>
                    </ul>
                </div>
            </div>
            <SubmitButton />
        </div>
    );
}
