import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import check from "../../images/checkmark.png";
import "../../css/theme.css";

export default function Theme() {
    const storedTheme = localStorage.theme ? localStorage.theme : "theme_1";
    const [theme, setTheme] = useState(storedTheme);
    let currentProject = localStorage.currentProject ? localStorage.currentProject : 1;
    const appState = localStorage.getItem("pr_"+ currentProject + '_appState');

    const handleClick = event => {
        event.preventDefault();

        console.log(event.target.dataset.theme !== undefined);
        if (event.target.dataset.theme !== undefined) {
            document.querySelectorAll("ul li.theme").forEach(node => {
                node.classList.remove("active");
            });
            setTheme(event.target.dataset.theme);
            localStorage.setItem("theme", event.target.dataset.theme);
        }
    };

    useEffect(() => {
        document.querySelector("body").classList.remove("theme_1", "theme_2", "theme_3");
        document.querySelector("body").classList.add(storedTheme);
        document.querySelector("ul li.theme." + storedTheme).classList.add("active");
    }, [theme, storedTheme]);

    const SubmitButton = withRouter(({ history, link }) => (
        <button className="next-button"
            onClick={(e) => {
                e.preventDefault();
                history.push(link);}}
        />
    ));

    const Destination = () => {
        if (appState == 'intro') {
            return <SubmitButton link="/intro" />
        } else {
            return <SubmitButton link="/menu" />
        }
    }

    return (
        <div className="theme-container">
            <h1 style={{ textAlign: 'center', color: "rgba(50, 119, 153, 1)", fontSize: '40px',marginTop:'30px' }}>Choose a Style</h1>
            <ul className="theme-wrapper">
                <li className="theme theme_1"
                    data-theme="theme_1"
                    onClick={handleClick}
                ><img className="check" src={check} alt="Pink Swatch. "/></li>

                <li className="theme theme_2"
                    data-theme="theme_2"
                    onClick={handleClick}
                ><img className="check" src={check} alt="Yellow Swatch. "/></li>
                <li className="theme theme_3"
                    data-theme="theme_3"
                    onClick={handleClick}
                ><img className="check" src={check} alt="Blue Swatch. "/></li>
            </ul>

            <div className="submit-wrapper" >
                <Destination/>
            </div>
        </div>
    );
}
